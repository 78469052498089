.progress-message {

    opacity: 1;
    color: rgba(27,27,27,1);
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    line-height: 32px;
}
