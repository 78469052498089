.response-field {
    min-height: 400px !important;
    background-color: #F3F3F3  !important ;
    border-color: #CCCCCC !important;
}

.alert {
    display: flex;
    background-color: white !important;
    font-size: 16px;
    font-weight: 600;
}

.logo {
    font-size: 1.6rem;
    position: absolute;
    left: 12px;
}

.body {
    padding-left: 1.8rem;
}


.header-label {
    opacity: 1;
    color: rgba(27,27,27,1);
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
}

.header-value {
    opacity: 1;
    color: rgba(27,27,27,1);
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    text-transform: capitalize;
}