.error-modal-header {
  background-color:  #CD0101 !important;
  color: white !important;
}

.error-modal-title {
    color: white !important;
  }

  .btn-close:after {
    color: white !important;
  }

  .sub-group-label {
    opacity: 1;
color: rgba(27,27,27,1);
font-size: 20px;
font-weight: 400;
font-style: normal;
letter-spacing: 0px;
text-align: left;
line-height: 24px;
  }

  .optional-label {
    opacity: 1;
color: rgba(106,105,105,1);
font-size: 0.875em;
font-weight: 400;
font-style: normal;
letter-spacing: 0px;
text-align: left;
margin-top: 0.25rem !important;
  }

  .total-label{
    opacity: 1;
color: rgba(27,27,27,1);
font-size: 20px;
font-weight: 400;
font-style: normal;
letter-spacing: 0px;
text-align: right;
  }

  .invalid-feedback{
    margin-top: 0.25rem !important;
  }

  @media screen and (min-width: 1280px) {
    .wrapper {width: 160px}

  }

  #hostedSessionForm iframe {
    height: 44px !important;
  }