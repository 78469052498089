input.is-invalid{
    border-color: red !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e") !important;
    background-repeat: no-repeat !important;
    background-position-x: calc(100% - 10px) !important;
    background-position-y: 40% !important;
    background-size: 20px !important;
}

select.is-invalid{
    border-color: red !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e") !important;
    background-repeat: no-repeat !important;
    background-position-x: calc(100% - 20px) !important;
    background-position-y: 40% !important;
    background-size: 20px !important;
}

.fds-pl-primary{
    height: 220px !important;
}

.fds-pl-gray{
    height: 152px !important;
}

nav > .container{
    max-width: "100% !important";
}

.modal-content > .modal-header >.btn-close::after {

    color: gray !important;
   

}

.select {
    position: relative;
}

.select:after {
        content: "\e902";
        width: 20px;
        height: 20px;
        font-family: "fds-glyphs";
        color: gray;
        position: absolute;
        right: 0px;
        top: 11px;
        font-size: 0.8em;
        z-index: 1;
        pointer-events: none;
    }

#embed-target
{
    min-height: 900px !important;
    transition: none !important;
}