.sendAs-label
{
    color: rgba(27,27,27,1);
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
}

#messageSubject{
    color: black !important;
    background-color: white !important;
}

.toast-header .btn-close {
    margin-left: auto !important;
}

#messageBody{
    color: black !important;
    background-color: white !important;
}

.createPaymentLink-subheader
{
    opacity: 1;
    color: rgba(27,27,27,1);
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    line-height: 32px;
}